import { CORE_DELIVERY_ROLE, DRIVER_ROLE } from "~/libs/constants";

/**
 * @typedef {object} Shipment
 * @property {string} trackingNumber
 * @property {0 | 1 | 2 | 3 | 4} status
 * @property {EcDeliveryRule} customer
 * @property {string} customerName                                         // admin限定のフィールド
 * @property {boolean} signatureRequired                                   // admin限定のフィールド
 * @property {string} releasedAt                                           // admin限定のフィールド
 * @property {string} customerOrderId                                      // admin限定のフィールド
 * @property {string} receiverTel
 * @property {string} receiverPostcode
 * @property {string} receiverAddress1
 * @property {string} receiverAddress2
 * @property {string} correctedReceiverAddress
 * @property {string} receiverName
 * @property {string} receiverEmailAddress                                 // admin限定のフィールド
 * @property {string} desiredDate
 * @property {string} desiredTime
 * @property {string} shipperTel                                           // admin限定のフィールド
 * @property {string} shipperPostcode                                      // admin限定のフィールド
 * @property {string} shipperAddress1                                      // admin限定のフィールド
 * @property {string} shipperAddress2                                      // admin限定のフィールド
 * @property {string} shipperName                                          // admin限定のフィールド
 * @property {number} cubicSize                                            // admin限定のフィールド
 * @property {number} relayLocationId
 * @property {string} locationShortName                                    // admin限定のフィールド
 * @property {number} delivererId                                          // admin限定のフィールド
 * @property {string} delivererName                                        // admin限定のフィールド
 * @property {number} driverId                                             // admin限定のフィールド
 * @property {string} driverUserName                                       // admin限定のフィールド
 * @property {string} driverDisplayName                                    // admin限定のフィールド
 * @property {string} createdAt                                            // admin限定のフィールド
 * @property {string} inTransitAt                                          // admin限定のフィールド
 * @property {string} heldInDepotAt                                        // admin限定のフィールド
 * @property {string} outForDeliveryAt
 * @property {string} deliveredAt                                          // admin限定のフィールド
 * @property {number} inTransitLocationId                                  // admin限定のフィールド
 * @property {string} inTransitLocationName                                // admin限定のフィールド
 * @property {number} heldInDepotLocationId                                // admin限定のフィールド
 * @property {string} heldInDepotLocationName                              // admin限定のフィールド
 * @property {number} outForDeliveryLocationId
 * @property {string} outForDeliveryLocationName                           // admin限定のフィールド
 * @property {string} unattendedDeliveryPhotoId                            // admin限定のフィールド
 * @property {string} unattendedDeliveryPhotoUrl                           // admin限定のフィールド
 * @property {boolean} unattendedDeliveryPhotoUploaded                     // admin限定のフィールド
 * @property {boolean} signaturePhotoUploaded                              // admin限定のフィールド
 * @property {boolean} supportTheftInsurance
 * @property {boolean} supportAutoLockUnlocking
 * @property {number} cashOnDeliveryAmount
 * @property {0 | 1 | 2 | 3 | 4 | 5} packageDropPlace
 * @property {0 | 1 | 2 | 3 | 4 | 5} actualPackageDropPlace                // admin限定のフィールド
 * @property {boolean} damaged
 * @property {Array<ExtraEvent>} extraEvent
 * @property {string} deliveryBoxNumber                                    // admin限定のフィールド
 * @property {string} deliveryBoxPin                                       // admin限定のフィールド
 * @property {number} numberOfDeliveryAttempts
 * @property {RedeliveryContext} redeliveryContext
 * @property {SpecifiedPickupDatetime} specifiedPickupDatetime
 * @property {0 | 1 | 2 | 3} returnStatus
 * @property {0 | 1 | 2 | 3 | 4 | 5 | 6} returnReason
 * @property {string} waitingForReturnAt
 * @property {number} waitingForReturnLocationId
 * @property {string} waitingForReturnLocationName                         // admin限定のフィールド
 * @property {string} returningAt
 * @property {string} returnedAt
 * @property {boolean} lost                                                // admin限定のフィールド
 * @property {string} lostAt                                               // admin限定のフィールド
 * @property {string} ecDelivererInternalMessage
 * @property {string} delivererInternalMessage
 * @property {string} token                                                // admin限定のフィールド
 * @property {number} version                                              // admin限定のフィールド
 *
 * @typedef {object} LocalExtraShipment
 * @property {"未" | "済" | "不"} statusText
 * @property {import("~/libs/constants").AddressTypeForMap} addressForMap
 * @property {string} enteredAddress
 * @property {{latitude: number, longitude: number}} latlon
 * @property {string} distance
 * @property {boolean} unacquired
 * @property {number} order
 * @property {number} deliveredTimestamp
 * @property {boolean} isManuallyInputted
 * @property {boolean} cashOnDeliveryRequred
 * @property {import("~/libs/constants").LostOrDamagedTypes} lostOrDamaged
 * @property {boolean} searchingForPackage
 * @property {string} personalMemo
 * @property {ShippingKnowledge} shippingKnowledge
 * @property {import("~/libs/constants").receivedPushTypes} receivedPushType
 *
 * @typedef {Shipment & LocalExtraShipment} DeliveryPackage
 *
 * @typedef {object} EcDeliveryRule
 * @property {string} companyId
 * @property {boolean} supportTheftInsurance
 * @property {boolean} supportAutoLockUnlocking
 * @property {boolean} signatureRequired
 * @property {boolean} supportCashOnDelivery
 * @property {number} maxCashOnDeliveryAmount
 * @property {boolean} emailNotificationRequired
 * @property {boolean} deliveryNoticeRequired
 * @property {boolean} deliveryNoticeRequiredForLocker
 * @property {number} deliveryDays
 * @property {Array<0 | 1 | 2 | 3 | 5 | number>} availableDeliveryMethod
 * @property {Array<0 | 1 | 2 | 3 | 5 | number>} receiverSelectableDeliveryMethod
 *
 * @typedef {object} ExtraEvent
 * @property {string} time
 * @property {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | number} extraEventType
 *
 * @typedef {object} DateAndTimeFrame
 * @property {string} date
 * @property {string} timeFrame
 *
 * @typedef {object} RedeliveryContext
 * @property {0 | 1 | number} redeliveryDatetimeSpecMethod
 * @property {Array<string>} timeFramePreset
 * @property {Array<DateAndTimeFrame>} redeliveryUnavailability
 * @property {DateAndTimeFrame} adjustedRedeliveryDatetime
 * @property {boolean} notificationResend
 *
 * @typedef {object} SpecifiedPickupDatetime
 * @property {DateAndTimeFrame} desiredRedeliveryDatetime
 * @property {Array<DateAndTimeFrame>} availablePickupDatetime
 *
 * @typedef {object} ShippingKnowledge
 * @property {import("~/libs/backendApi").ByAddressKnowledge} [byAddress]
 * @property {Array<import("~/libs/backendApi").NeighborhoodKnowledge>} [neighborhoods]
 *
 * @typedef {{id: number, name: string, locationShortName: string, latitude: number, longitude: number}} DepotLocation
 * @typedef {object} DepotLocationWithPrefecture
 * @property {string} prefecture
 * @property {Array<DepotLocation>} centers
 *
 * @typedef {object} AppPageStore
 * @property {import("~/libs/constants").AppPageType} type
 * @property {string} name
 * @property {object} [props]
 *
 * @typedef {object} TimeFramePreset
 * @property {string} name
 * @property {Array<string>} timeFrameList
 *
 * @typedef {object} PushNotificationInfo
 * @property {{title: string, body: string}} message
 * @property {string} trackingNumber
 * @property {string} [correctedReceiverAddress]
 * @property {string} [delivererInternalMessage]
 * @property {string} [ecDelivererInternalMessage]
 * @property {DateAndTimeFrame} [adjustedRedeliveryDatetime]
 *
 * @typedef {object} Company
 * @property {number} id
 * @property {string} name
 * @property {number} type
 * @property {Array<string>} [switchableRoles]
 * @property {Array<Company>} [switchableCompanies]
 */

/**
 * @typedef {object} AppVersion
 * @property {number} major
 * @property {number} minor
 * @property {number} patch
 */

/**
 * エラーハンドリングのために使用するユーザ定義例外クラス。
 * メッセージにはユーザに表示可能なメッセージを設定する。
 */
export class HandledError extends Error {
  constructor(message) {
    super(message);
    this.name = "HandledError";
  }
}

export class AppContext {
  /** AppContextの互換性バージョン（0はデータなしでnewしただけの状態を示す特殊な値） @type {number} */
  version;
  /** @type {0 | 1 | 2} */
  deliveryListSortType;
  /** @type {boolean} */
  firstLoginOpened;
  /** @type {boolean} */
  firstLoginCompleted;
  /** @type {boolean} */
  firstDeliveryListOpened;
  /** @type {boolean} */
  firstOutForDeliveryStarted;
  /** @type {boolean} */
  firstTroubleRegistered;
  /** @type {boolean} */
  firstDeliveryCompleteOpened;

  /** @type {boolean} QRコードの読み取りで高解像度モードを使用するか否か */
  useOpenCvQrCodeScanner = true;
  /** @type {boolean} カメラを手動で指定するか否か */
  useManualCameraSelection = false;
  /** @type {string} 手動で指定したフロントカメラのID */
  selectedFrontCameraId;
  /** @type {string} 手動で指定したバックカメラのID */
  selectedBackCameraId;

  /** @type {boolean} オフラインモードか否か */
  offlineMode;
  /** @type {import("~/libs/constants").OfflineModeTypes} オフラインモードタイプ */
  offlineModeType;
  /** @type {boolean} オンラインモードへの切替えに失敗している場合にtrue */
  failedToSwitchOnline;

  /** @type {Array<TimeFramePreset>} 再配達希望日時の指定で用いる時間帯のプリセットリスト */
  timeFramePresetList;

  store() {
    localStorage.setItem("appContext", JSON.stringify(this));
  }
  erase() {
    localStorage.removeItem("appContext");
  }
}

export class UserContext {
  /** UserContextの互換性バージョン（0はデータなしでnewしただけの状態を示す特殊な値） @type {number} */
  version;
  /**
   * @type {{
   *   username: string,
   *   roles: Array<string>,
   *   accessToken: string,
   *   refreshToken: string,
   *   expiresIn: number,
   *   refreshExpires: number,
   *   loginTime: number,
   *   displayName: string,
   *   companyId: number,
   *   companyName: string,
   *   emailAddress: string,
   *   switchableRoles: Array<string>,
   *   switchableCompanies: Array<Company>,
   * }}
   */
  loginUser;

  /** @type {Array<DeliveryPackage>} */
  deliveryList;

  /** @type {boolean} */
  undelivered; // FIXME: アンチパターン：確実に更新しないと配達リストと整合性が取れなくなるため、せめて関数化すべき（追記：使用箇所を見る限り関数化する必要すらなさそう）

  /** @type {Date} 指定時間内の荷物をリマインドした時刻 */
  timeOfShowingDeliveryRemind;

  /**
   * @type {Array<InTransitDeliveryInfo>}
   * @typedef {{
   *  transportSourceId : number,
   *  deliveryInfoList : Array<{
   *   transportDestinationId : number,
   *   basketCarList : Array<{
   *    k : number,
   *    v : TrackingNumberList,
   *    c? : boolean,
   *   }>
   *  }>
   * }} InTransitDeliveryInfo
   * @typedef {Array<string>} TrackingNumberList
   */
  inTransitDeliveryList;

  /**
   * @type {{
   *  bundleId : string,
   *  deviceToken : string
   * }}
   */
  iosNativeAppPushInfo;

  /**
   * ログイン要否を返す。
   * 必要なフィールドが欠損しているか、トークンの期限が切れている場合はログインが必要と判断する。
   * @returns {boolean}
   */
  needsLogin() {
    return (
      !this.loginUser ||
      this.loginUser.roles?.length <= 0 ||
      !this.loginUser.accessToken ||
      (this.loginUser.loginTime ?? 0) <= 0 ||
      (this.loginUser.expiresIn ?? 0) <= 0 ||
      Date.now() >=
        Math.max(
          this.loginUser.loginTime + this.loginUser.expiresIn * 1000,
          this.loginUser.refreshExpires ?? 0,
        )
    );
  }

  /**
   * 宅配ドライバーのロールを持っているか否かを返す。
   * @returns {boolean}
   */
  hasDriverRole() {
    return this.loginUser?.roles?.includes(DRIVER_ROLE);
  }

  /**
   * 基幹配送担当のロールを持っているか否かを返す。
   * @returns {boolean}
   */
  hasContractDriverRole() {
    return this.loginUser?.roles?.includes(CORE_DELIVERY_ROLE);
  }

  /**
   * 配達リストのステータスごと（未、済、不）の件数を返す。
   * @returns {{
   *   未: number,
   *   済: number,
   *   不: number,
   * }}
   */
  getNumberOfPackagesGroupByStatus() {
    const numberOfPackagesGroupByStatus = {
      未: 0,
      済: 0,
      不: 0,
    };
    if (this.deliveryList) {
      for (const delivery of this.deliveryList) {
        numberOfPackagesGroupByStatus[delivery.statusText] += 1;
      }
    }
    return numberOfPackagesGroupByStatus;
  }

  /**
   * 輸送中荷物の総個数を返す。
   * @returns {number}
   */
  getNumberOfInTransitPackages() {
    let count = 0;
    if (this.inTransitDeliveryList) {
      for (const inTransitDeliveryInfo of this.inTransitDeliveryList) {
        for (const deliveryInfo of inTransitDeliveryInfo.deliveryInfoList) {
          for (const basketCar of deliveryInfo.basketCarList) {
            count += basketCar.v.length;
          }
        }
      }
    }
    return count;
  }

  store() {
    localStorage.setItem("userContext", JSON.stringify(this));
  }
  erase() {
    for (const filedName in this) {
      delete this[filedName];
    }
    localStorage.removeItem("userContext");
  }
}
