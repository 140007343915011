import { format as formatDate } from "date-fns";
import ky from "ky";
import { UAParser } from "ua-parser-js";

import appManager from "~/libs/appManager";
import backendApiMocker from "~/libs/backendApiMocker";
import iosNativeApp from "~/libs/iosNativeApp";

/**
 * @typedef {{
 *   data: *,
 *   error?: ErrorResponse,
 * }} JsonResponse
 *
 * @typedef {{
 *   title: string,
 *   message: string,
 *   details: {[key: string]: object},
 * }} ErrorResponse
 *
 * @typedef {{
 *   username: string,
 *   roles: Array<string>,
 *   accessToken: string,
 *   refreshToken: string,
 *   tokenType: string,
 *   expiresIn: number,
 *   refreshExpiresIn: number,
 *   displayName: string,
 *   companyId: number,
 *   companyName: string,
 *   emailAddress: string,
 *   switchableRoles: Array<string>,
 *   switchableCompanies: Array<import("~/libs/commonTypes").Company>,
 * }} LoginResponse
 *
 * @typedef {{
 *   status: 0 | 1 | 2 | 3 | 4,
 *   customerId: number,
 *   customerName: string,
 *   signatureRequred: boolean,
 *   deliveryDays: number,
 *   address: string,
 *   correctedReceiverAddress: string,
 *   receiverName: string,
 *   desiredDate: string,
 *   desiredTime: string,
 *   relayLocationId: number,
 *   inTransitAt: string,
 *   heldInDepotAt: string,
 *   outForDeliveryAt: string,
 *   inTransitLocation: number,
 *   heldInDepotLocation: number,
 *   outForDeliveryLocation: number,
 *   supportTheftInsurance: boolean,
 *   supportAutolockUnlocking: boolean,
 *   cashOnDeliveryAmount: number,
 *   packageDropPlace: number,
 *   damaged: boolean,
 *   extraEvent: Array<import("~/libs/commonTypes").ExtraEvent>,
 *   numberOfDeliveryAttempts: number,
 *   redeliveryContext?: {adjustedRedeliveryDatetime: {date: string, timeFrame: string}},
 *   specifiedPickupDatetime?: {desiredRedeliveryDatetime: {date: string, timeFrame: string}, availablePickupDatetime: Array<{date: string, timeFrame: string}>},
 *   returnStatus: 0 | 1 | 2 | 3,
 *   returnReason: 0 | 1 | 2 | 3 | 4 | 5 | 6,
 *   requestingForReturnAt: string,
 *   waitingForReturnAt: string,
 *   waitingForReturnLocation: number,
 *   returningAt: string,
 *   returnedAt: string,
 *   lost: boolean,
 * }} QrScanResponse
 *
 * @typedef {{
 *   trackingNumber: string,
 *   address: string,
 * }[]} AddressKnowledgeRequest
 *
 * @typedef {{
 *   trackingNumber: string,
 *   address: string,
 *   latitude: number,
 *   longitude: number,
 * }[]} AddressKnowledgeResponse
 *
 * @typedef {{
 *   byAddress: RegisterByAddressKnowledgeRequest,
 *   neighborhood: RegisterNeighborhoodKnowledgeRequest,
 * }} RegisterKnowledgeRequest
 *
 * @typedef {{
 *   address: string,
 *   memo: string,
 *   updatedBy: string,
 *   updatedAt: string,
 *   receiverName: string,
 * }} RegisterByAddressKnowledgeRequest
 *
 * @typedef {{
 *   latitude: number,
 *   longitude: number,
 *   memo: string,
 *   updatedBy: string,
 *   updatedAt: string,
 *   address: string,
 * }} RegisterNeighborhoodKnowledgeRequest
 *
 * @typedef {{
 *   byAddress: UpdateByAddressKnowledgeRequest,
 *   neighborhood: UpdateNeighborhoodKnowledgeRequest,
 * }} UpdateKnowledgeRequest
 *
 * @typedef {{
 *   id: number,
 *   memo: string,
 *   updatedBy: string,
 *   updatedAt: string,
 *   receiverName: string,
 * }} UpdateByAddressKnowledgeRequest
 *
 * @typedef {{
 *   id: number,
 *   memo: string,
 *   updatedBy: string,
 *   updatedAt: string,
 *   address: string,
 * }} UpdateNeighborhoodKnowledgeRequest
 *
 * @typedef {Array<SearchKnowledgeRequest>} SearchKnowledgeRequests
 *
 * @typedef {{
 *   trackingNumber: string,
 *   address: string,
 *   radius: number,
 *   latitude: number,
 *   longitude: number,
 * }} SearchKnowledgeRequest
 *
 * @typedef {{
 *   searchKnowledgeResponses: Array<SearchKnowledgeResponse>,
 * }} SearchKnowledgeResponses
 *
 * @typedef {{
 *   trackingNumber: string,
 *   byAddress: ByAddressKnowledge,
 *   neighborhoods: Array<NeighborhoodKnowledge>,
 * }} SearchKnowledgeResponse
 *
 * @typedef {{
 *   id: number,
 *   memo: string,
 *   updatedBy: string,
 *   updatedAt: string,
 *   receiverName: string,
 * }} ByAddressKnowledge
 *
 * @typedef {{
 *   id: number,
 *   sameAddress: boolean,
 *   memo: string,
 *   updatedBy: string,
 *   updatedAt: string,
 *   address: string,
 * }} NeighborhoodKnowledge
 *
 * @typedef {{
 *   bundleId: string,
 *   deviceToken: string,
 * }} ApnsRequest
 */

/** リクエスト・操作を特定するIDのprefix **/
const operationIdPrefix = `${formatDate(new Date(), "MMddHHmm")}-${(
  import.meta.env.VITE_COMMIT_HASH || "NA"
).substring(0, 7)}-${(() => {
  const parsedUA = UAParser(navigator.userAgent);
  return `${parsedUA.os?.name}_${parsedUA.os?.version}/${parsedUA.browser?.name}_${parsedUA.browser?.version}`.replace(
    / /g,
    "",
  );
})()}-`;

/** @type {number} リクエスト・操作を特定するIDの連番 */
let operationSequenceNumber = 0;

/** @type {import("ky").KyInstance} */
let kyInstance;

/** 認証トークン更新中フラグ */
let tokenUpdateInprogress = false;

const backendApi = {
  initialize: (
    /** @type {import("~/libs/commonTypes").UserContext} */ userContext,
  ) => {
    kyInstance = createInstance(userContext);
  },

  /**
   * ログイン
   * @param {{username: string, password: string}} data
   * @returns {Promise<LoginResponse>}
   */
  async login(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance.post("login", { json: data }).json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * ログイン（EC切替え）
   * @param {{username: string, password: string}} data
   * @param {string} role
   * @returns {Promise<LoginResponse>}
   */
  async loginToSwitchCompany(data, role) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("login", {
        headers: { "X-Request-Role": role },
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 初期パスワード変更
   * @param {{userName: string, oldPassword: string, newPassword: string}} data
   */
  async changeInitialPassword(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("users/reset-password", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * パスワードリセット依頼
   * @param {{userName: string, emailAddress: string}} data
   */
  async reqeustPasswordReset(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("users/password/request-reset", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * パスワードリセット依頼
   * @param {{userName: string, pin: string, newPassword: string}} data
   */
  async passwordReset(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("users/password/reset", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * パスワード変更
   * @param {{oldPassword: string, newPassword: string}} data
   */
  async changePassword(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("users/change-password", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * 配達リスト取得
   * @returns {Promise<Array<import("~/libs/commonTypes").Shipment>>}
   */
  async getDeliveryList() {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance.get("shipments/driver/list").json();
    assertNotErrorResponse(response);
    return response.data ?? []; // リストが空の場合にBEがdataプロパティのない空JSONを返すため強制変換
  },

  /**
   * 配送情報取得(1件)
   * @param {string} trackingNumber
   * @returns {Promise<import("~/libs/commonTypes").Shipment>}
   */
  async getShipmentInfo(trackingNumber) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance.get(`shipments/${trackingNumber}`).json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 配送ステータスの更新
   * @param {object} data // TODO: 型を定義する
   * @returns {Promise<object>} // TODO: 型を定義する
   */
  async updateShipmentStatus(data) {
    assertNotOffline();
    let requestOptions;
    if (data instanceof FormData) {
      requestOptions = { body: data };
    } else {
      requestOptions = { json: data };
    }

    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipments/status-update", requestOptions)
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * QRスキャン時配送情報取得
   * @param {string} trackingNumber
   * @param {import("~/libs/constants").QrScanModes} mode
   * @returns {Promise<QrScanResponse>}
   */
  async getShipmentInfoByQrScan(trackingNumber, mode) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .get(`shipments/${trackingNumber}/qrscan?mode=${mode}`)
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 配送センター一覧の取得
   * @returns {Promise<Array<import("~/libs/commonTypes").DepotLocationWithPrefecture>>}
   */
  async getDepotLocations() {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance.get("locations").json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 通知の送信
   * @param {string} trackingNumber
   */
  async sendNotification(trackingNumber) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .get(`shipments/${trackingNumber}/notify`)
      .json();
    assertNotErrorResponse(response);
  },

  /**
   *  Web Push用の公開鍵取得
   * @returns {Promise<{publicKey: string}>}
   */
  async getWebPushPublicKey() {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance.get("webpush/public-key").json();
    assertNotErrorResponse(response);
    return response.data.publicKey;
  },

  /**
   * Web Pushのサブスクリプション登録
   * @param {{endpoint: string, expiration_time: number, keys: {p256dh: string, auth: string}}} data
   */
  async registerWebPushSubscription(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("webpush/subscription", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * Web Push送信
   * @param {{notificationType: number}} data
   */
  async sendWebPush(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("webpush/send", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * 配達ナレッジ登録
   * @param {RegisterKnowledgeRequest} data
   * @returns {Promise<object>} // TODO: 型を定義
   */
  async registerShippingNnowledge(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipping-knowledge", { json: data })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 配達ナレッジ更新
   * @param {UpdateKnowledgeRequest} data
   * @returns {Promise<object>} // TODO: 型を定義
   */
  async updateShippingNnowledge(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipping-knowledge/update", { json: data })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 配達ナレッジ検索
   * @param {SearchKnowledgeRequests} data
   * @returns {Promise<object>} // TODO: 型を定義
   */
  async searchShippingNnowledge(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipping-knowledge/search", { json: data })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * APNsのデバイストークン登録
   * @param {ApnsRequest} data
   */
  async registerApnsDeviceToken(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance.post("apns", { json: data }).json();
    assertNotErrorResponse(response);
  },

  /**
   * APNsのデバイストークン削除
   * @param {ApnsRequest} data
   */
  async deleteApnsDeviceToken(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("apns/delete", { json: data })
      .json();
    assertNotErrorResponse(response);
  },
};
export default backendApi;

/**
 * オフライン時に発生する例外
 */
export class OfflineException extends Error {
  constructor() {
    super("Offline state");
    this.name = "OfflineException";
  }
}

/**
 * バックエンドからerrorプロパティを持つレスポンスを受け取った場合に発生する例外
 */
export class ErrorResponseException extends Error {
  errorResponse;

  /**
   * @param {ErrorResponse} errorResponse
   */
  constructor(errorResponse) {
    super("Receive error response");
    this.name = "ErrorResponseException";
    this.errorResponse = errorResponse;
  }
}

/**
 * kyのインスタンスを生成する。
 * @param {import("~/libs/commonTypes").UserContext} [userContext]
 * @returns {import("ky").KyInstance}
 */
function createInstance(userContext) {
  /** @type {import("ky").Options} */
  const options = {
    prefixUrl: import.meta.env.VITE_API_SERVER_URL,
    timeout: 20000,
    retry: 1,
    parseJson: (text) => (text !== "" ? JSON.parse(text) : null),
    hooks: {
      beforeRequest: [
        (request, options) => {
          // 認証トークンの設定
          if (userContext?.loginUser?.accessToken) {
            request.headers.set(
              "Authorization",
              "Bearer " + userContext.loginUser.accessToken,
            );
          }

          // リクエスト・操作を特定するIDを設定
          request.headers.set(
            "X-Operation-Id",
            operationIdPrefix +
              iosNativeApp.getCurrentVersion().replace(/(.+)/, "$1-") +
              ++operationSequenceNumber,
          );

          // モック用のリクエスト書き換え処理
          _REMOVABLE_MOCK_: {
            if (import.meta.env.VITE_USE_MOCK_API) {
              backendApiMocker.prepareRequest(request, options);
            }
            break _REMOVABLE_MOCK_; // 未使用ラベルがViteの事前処理で削除されてesbuildに渡せない対策
          }
        },
        // eslint-disable-next-line no-unused-vars
        async (request, options) => {
          if (
            !tokenUpdateInprogress &&
            userContext?.loginUser?.accessToken &&
            userContext?.loginUser?.refreshToken
          ) {
            const currentTime = Date.now();
            const accessTokenRefreshThreshold =
              userContext.loginUser.loginTime +
              Math.max(
                Math.floor(userContext.loginUser.expiresIn / 2),
                60 * 60, // min 1 hour
              ) *
                1000;
            if (currentTime >= accessTokenRefreshThreshold) {
              tokenUpdateInprogress = true;

              const accessTokenExpires =
                userContext.loginUser.loginTime +
                userContext.loginUser.expiresIn * 1000;
              console.log(
                `アクセストークンの更新が必要：${new Date(
                  currentTime,
                ).toLocaleString()} ≧ ${new Date(
                  accessTokenRefreshThreshold,
                ).toLocaleString()} (${new Date(
                  accessTokenExpires,
                ).toLocaleString()})`,
              );

              // 新しいアクセストークンを取得（ログインAPIの呼出）
              const currentAccessToken = userContext.loginUser.accessToken;
              const currentRefreshToken = userContext.loginUser.refreshToken;
              try {
                // リフレッシュトークンもどきの方が有効期限が長い場合は一時的に差し替え
                if (userContext.loginUser.refreshExpires > accessTokenExpires) {
                  userContext.loginUser.accessToken = currentRefreshToken;
                }

                const loginResponse = await backendApi.login({
                  username: userContext.loginUser.username,
                  password: "N/A",
                });
                userContext.loginUser = {
                  username: loginResponse.username,
                  roles: loginResponse.roles,
                  accessToken: loginResponse.accessToken,
                  refreshToken: currentRefreshToken,
                  expiresIn: loginResponse.expiresIn,
                  refreshExpires:
                    loginResponse.refreshExpiresIn > 0
                      ? currentTime + loginResponse.refreshExpiresIn * 1000
                      : undefined,
                  loginTime: currentTime,
                  displayName: loginResponse.displayName,
                  companyId: loginResponse.companyId,
                  companyName: loginResponse.companyName,
                  emailAddress: loginResponse.emailAddress,
                  switchableRoles: loginResponse.switchableRoles,
                  switchableCompanies: loginResponse.switchableCompanies,
                };
                userContext.store();

                // 本来のリクエストに取得したアクセストークンを設定
                request.headers.set(
                  "Authorization",
                  "Bearer " + userContext.loginUser.accessToken,
                );
              } catch (error) {
                console.error(error); // use non-logger explicitly
                if (userContext.loginUser) {
                  if (
                    userContext.loginUser.accessToken === currentRefreshToken
                  ) {
                    userContext.loginUser.accessToken = currentAccessToken;
                    delete userContext.loginUser.refreshToken;
                  }
                }
              } finally {
                tokenUpdateInprogress = false;
              }
            }
          }
        },
      ],

      afterResponse: [
        (request, options, response) => {
          const appRequiredTime = Number.parseInt(
            response.headers.get("x-app-required-time"),
          );
          if (Number.isInteger(appRequiredTime)) {
            appManager.offerUpdateIfNeeded(appRequiredTime);
          }
        },
      ],

      beforeError: [
        async (error) => {
          if (
            error.response?.headers
              ?.get("Content-Type")
              ?.match(/^application\/json(?:;|$)/)
          ) {
            try {
              /** @type {ErrorResponse} */
              const errorResponse = (await error.response.json())?.error;
              if (errorResponse) {
                error["errorResponse"] = errorResponse;
              }
            } catch (error) {
              console.error(error); // use non-logger explicitly
            }
          }
          return error;
        },
      ],

      beforeRetry: [
        async ({ request, error, retryCount }) => {
          console.log(`retry request (${retryCount}): ${request.url}`, error);
        },
      ],
    },
  };

  // モック用のレスポンス書換処理
  _REMOVABLE_MOCK_: {
    if (import.meta.env.VITE_USE_MOCK_API) {
      if (!options.hooks.afterResponse) {
        options.hooks.afterResponse = [];
      }
      options.hooks.afterResponse.push(backendApiMocker.rewriteResponse);
    }
    break _REMOVABLE_MOCK_; // 未使用ラベルがViteの事前処理で削除されてesbuildに渡せない対策
  }

  return ky.extend(options);
}

/**
 * オフライン状態でないことをアサートする。
 */
function assertNotOffline() {
  if (!navigator.onLine) {
    throw new OfflineException();
  }
}

/**
 * errorプロパティを持つレスポンスでないことをアサートする。
 * @param {JsonResponse} response
 */
function assertNotErrorResponse(response) {
  if (response.error) {
    throw new ErrorResponseException(response.error);
  }
}
